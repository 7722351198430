<template>
  <div class="flex flex-col text-left">
    <div class="flex flex-col">
      <div class="flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Titel el</label>
          <input
            type="text"
            v-model="$v.action.title.$model"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            :class="{ 'border-red-500': $v.action.title.$error }"
          />
        </div>

        <div class="w-full flex flex-col">
          <label for="">Typ</label>
          <select
            v-model="$v.action.type.$model"
            class="border rounded px-2 py-0.15 w-full"
            :class="{ 'border-red-500': $v.action.type.$error }"
          >
            <option value="">Typ auswählen</option>
            <option value="intern">Intern</option>
            <option value="extern">Extern</option>
          </select>
        </div>

        <div class="w-full flex flex-col">
          <label for="">Realisation</label>
          <date-picker
            v-model="action.realization"
            :input-class="
              `border rounded p-1 text-xs appearance-none focus:outline-none w-full`
            "
          />
        </div>
      </div>

      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Kosten (Mitarbeiter)</label>
          <input
            type="text"
            v-model="$v.action.cost_employe.$model"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            :class="{ 'border-red-500': $v.action.cost_employe.$error }"
          />
        </div>

        <div class="w-full flex flex-col">
          <label for="">Kosten (Firma)</label>
          <input
            type="text"
            v-model="$v.action.cost_company.$model"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            :class="{ 'border-red-500': $v.action.cost_company.$error }"
          />
        </div>

        <div class="w-full flex flex-col">
          <label for="">Status</label>
          <select
            v-model="$v.action.status.$model"
            class="border rounded px-2 py-0.15 w-full"
            :class="{ 'border-red-500': $v.action.status.$error }"
          >
            <option value="">Status auswählen</option>
            <option value="open">Offen</option>
            <option value="cancel">Abbrechen</option>
            <option value="done">Abgeschlossen</option>
          </select>
        </div>
      </div>

      <div class="w-full flex space-x-2 mb-2">
        <div class="w-full flex flex-col">
          <label for="">Wirksamkeit</label>
          <select
            v-model="$v.action.effectivity.$model"
            class="border rounded px-2 py-0.15 w-full"
            :class="{ 'border-red-500': $v.action.effectivity.$error }"
          >
            <option value="">Wirksamkeit auswählen</option>
            <option value="sofort wirksam">sofort wirksam</option>
            <option value="innert 1-6 Monaten">innert 1-6 Monaten</option>
            <option value="innert 6-12 Monaten">innert 6-12 Monaten</option>
            <option value="innert 1-3 Jahren">innert 1-3 Jahren</option>
            <option value="in über 3 Jahren">in über 3 Jahren</option>
            <option value="gar nicht: die Massnahme war nicht wirksam"
              >gar nicht: die Massnahme war nicht wirksam</option
            >
          </select>
        </div>
      </div>
      <div class="w-full flex flex-col">
        <label for="">Beschreibung</label>
        <textarea
          v-model="$v.action.description.$model"
          rows="7"
          class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
          :class="{ 'border-red-500': $v.action.description.$error }"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { xhr } from '@/utils/axios'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'new-development-actions',
  props: ['payload', 'parent_id'],
  data() {
    return {
      action: {
        title: null,
        description: null,
        type: '',
        realization: null,
        cost_employe: 0,
        cost_company: 0,
        status: '',
        effectivity: '',
        new: true,
      },
    }
  },
  validations: {
    action: {
      title: {
        required,
      },
      description: {
        required,
      },
      type: {
        required,
      },
      cost_employe: {
        required,
      },
      cost_company: {
        required,
      },
      status: {
        required,
      },
      effectivity: {
        required,
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.action = { ...this.action, ...this.payload }
    },
    save() {
      this.$v.action.$touch()

      if (this.$v.$invalid) {
        this.$toast.warning('Bitte füllen Sie die erforderlichen Felder aus')
      } else {
        this.action['roleprofile'] = this.parent_id
        this.action['personal'] = this.parent_id

        if (this.action.id) {
          xhr
            .put(`/personal/developmentaction/${this.action.id}`, this.action, {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(() => {
              this.$bus.$emit('reload-development-actions')
              this.$emit('close-modal', { show: false })
            })
        } else {
          xhr
            .post(`/personal/developmentaction/`, this.action, {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(() => {
              this.$bus.$emit('reload-development-actions')
              this.$emit('close-modal', { show: false })
            })
        }
      }
    },
  },
  watch: {},
}
</script>

<style></style>
